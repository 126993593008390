<script setup lang="ts">
import questionRoutes from "@/router/questions";

const getLabel = (routeName: string, prefix: string) => {
    const name = routeName.replace(prefix, "");
    return name.charAt(0).toUpperCase() + name.slice(1);
};
</script>

<template>
    <div class="Sections">
        <ul>
            <li>
                <ul>
                    <li>
                        <router-link to="/"> Create user group </router-link>
                    </li>
                </ul>
                <h5>General</h5>
                <ul>
                    <li>
                        <router-link to="/application-use">
                            Application use
                        </router-link>
                    </li>

                    <li>
                        <router-link to="/ebook-use"> Ebook use </router-link>
                    </li>

                    <li>
                        <router-link to="/general-topscore">
                            General (topscore)
                        </router-link>
                    </li>
                </ul>
            </li>

            <li>
                <h5>Questions</h5>
                <ul>
                    <li v-for="(route, index) in questionRoutes" :key="index">
                        <router-link :to="route.path">
                            {{ getLabel(route.name, "Question") }}
                        </router-link>
                    </li>
                </ul>
            </li>

            <li>
                <h5>Course</h5>
                <ul>
                    <li>
                        <router-link to="/course-progression-done">
                            Progression (done)
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/course-progression-succeeded">
                            Progression (succeeded)
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/course-skip-count">
                            Skip chapter
                        </router-link>
                    </li>
                </ul>
            </li>
            <li>
                <h5>Exam</h5>
                <ul>
                    <li>
                        <router-link to="/exam-hand-in">
                            Exam hand in
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/exam-hand-in-topscore">
                            Exam hand in (topscore)
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/study-further">
                            Study further
                        </router-link>
                    </li>
                </ul>
            </li>

            <li>
                <h5>Video</h5>
                <ul>
                    <li>
                        <router-link to="/video-use">
                            Video use (general info)
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/video-use-count">
                            Video use (count)
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/video-use-topscore">
                            Video use (topscore)
                        </router-link>
                    </li>
                </ul>
            </li>

            <li>
                <h5>User</h5>
                <ul>
                    <li>
                        <router-link to="/user-timeline">
                            Timeline
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/user-table"> Table </router-link>
                    </li>
                </ul>
            </li>
            <li>
                <!--                <h5>Exams</h5>-->
                <!--                <ul>-->
                <!--                    <li>-->
                <!--                        <router-link to="/"> Success rate </router-link>-->
                <!--                    </li>-->
                <!--                </ul>-->
                <!--            </li>-->
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.Sections {
    height: 100%;
    overflow: auto;
    width: 250px;

    > ul {
        padding-left: 0;
    }
}

h5 {
    font-weight: 500;
    font-size: 1rem;
}

li {
    list-style: none;
    margin: 5px 0;
}

a {
    padding: 4px;

    &:hover {
        background: var(--color-blue-3);
    }

    &.router-link-active {
        background: var(--color-blue-7);
        color: #fff;
    }
}
</style>
