import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_button = _resolveComponent("etd-button")!
  const _component_etd_form = _resolveComponent("etd-form")!
  const _component_etd_form_error_message = _resolveComponent("etd-form-error-message")!
  const _component_etd_card = _resolveComponent("etd-card")!
  const _component_etd_container = _resolveComponent("etd-container")!

  return (_openBlock(), _createBlock(_component_etd_container, { gap: "xl" }, {
    default: _withCtx(() => [
      (!_ctx.asPopup)
        ? (_openBlock(), _createBlock(_component_etd_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_etd_form, {
                formValue: _ctx.formValue,
                "onUpdate:formValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValue) = $event)),
                "form-config": _ctx.formConfig,
                onSubmit: _ctx.atSubmit
              }, {
                submit: _withCtx(({ atSubmit }) => [
                  _createVNode(_component_etd_button, {
                    id: "login-submit",
                    theme: "orange",
                    size: "big",
                    onClick: atSubmit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("login")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["formValue", "form-config", "onSubmit"]),
              (_ctx.wrongCombinationEmailPassword)
                ? (_openBlock(), _createBlock(_component_etd_form_error_message, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("wrong_combination_email_password")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_etd_form, {
              formValue: _ctx.formValue,
              "onUpdate:formValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formValue) = $event)),
              "form-config": _ctx.formConfig,
              onSubmit: _ctx.atSubmit
            }, {
              submit: _withCtx(({ atSubmit }) => [
                _createVNode(_component_etd_button, {
                  id: "login-submit",
                  theme: "orange",
                  size: "big",
                  onClick: atSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Login ")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["formValue", "form-config", "onSubmit"]),
            (_ctx.wrongCombinationEmailPassword)
              ? (_openBlock(), _createBlock(_component_etd_form_error_message, {
                  key: 0,
                  id: "authentication-feedback"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("wrong_combination_email_password")), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    _: 1
  }))
}