import { RoutePath } from "@/types";

const routes: RoutePath[] = [
    {
        path: "/question-time-spent",
        name: "QuestionTimeSpent",
        component: () =>
            import("@/components/sections/questions/QuestionTimeSpent.vue"),
    },
    {
        path: "/question-time-spent-exam",
        name: "QuestionTimeSpentExam",
        component: () =>
            import("@/components/sections/questions/QuestionTimeSpentExam.vue"),
    },
    {
        path: "/question-exercise-count",
        name: "QuestionExerciseCount",
        component: () =>
            import("@/components/sections/questions/QuestionExerciseCount.vue"),
    },
    {
        path: "/question-exercise-topscore",
        name: "QuestionExerciseTopscore",
        component: () =>
            import(
                "@/components/sections/questions/QuestionExerciseTopscore.vue"
            ),
    },
    {
        path: "/question-exam-count",
        name: "QuestionExamCount",
        component: () =>
            import("@/components/sections/questions/QuestionExamCount.vue"),
    },
];

export default routes;
