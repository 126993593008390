import { RoutePath } from "@/types";
import questions from "./questions";
const ApplicationUse = () =>
    import("@/components/sections/general/ApplicationUse.vue");
const VideoUse = () => import("@/components/sections/general/VideoUse.vue");

export const sectionRoutes: RoutePath[] = [
    ...questions,
    {
        path: "/application-use",
        name: "ApplicationUse",
        component: ApplicationUse,
    },
    {
        path: "/video-use",
        name: "VideoUse",
        component: VideoUse,
    },
    {
        path: "/ebook-use",
        name: "EbookUse",
        component: () => import("@/components/sections/general/EbookUse.vue"),
    },
    {
        path: "/general-topscore",
        name: "GeneralTopscore",
        component: () =>
            import("@/components/sections/general/GeneralTopscore.vue"),
    },

    {
        path: "/user-timeline",
        name: "UserTimeline",
        component: () => import("@/components/sections/user/UserTimeline.vue"),
    },
    {
        path: "/user-table",
        name: "UserTable",
        component: () => import("@/components/sections/user/UserTable.vue"),
    },

    {
        path: "/exam-hand-in",
        name: "ExamHandIn",
        component: () => import("@/components/sections/exam/ExamHandIn.vue"),
    },
    {
        path: "/exam-hand-in-topscore",
        name: "ExamHandInTopscore",
        component: () =>
            import("@/components/sections/exam/ExamHandInTopscore.vue"),
    },
    {
        path: "/study-further",
        name: "ExamStudyFurther",
        component: () => import("@/components/sections/exam/StudyFurther.vue"),
    },
    {
        path: "/course-progression-done",
        name: "CourseProgressionDone",
        component: () =>
            import("@/components/sections/course/CourseProgressionDone.vue"),
    },
    {
        path: "/course-progression-succeeded",
        name: "CourseProgressionSucceeded",
        component: () =>
            import(
                "@/components/sections/course/CourseProgressionSucceeded.vue"
            ),
    },
    {
        path: "/course-skip-count",
        name: "CourseSkipCount",
        component: () =>
            import("@/components/sections/course/CourseSkipCount.vue"),
    },
    {
        path: "/video-use-count",
        name: "VideoUseCount",
        component: () =>
            import("@/components/sections/video/VideoUseCount.vue"),
    },
    {
        path: "/video-use-topscore",
        name: "VideoUseTopscore",
        component: () =>
            import("@/components/sections/video/VideoUseTopscore.vue"),
    },
];
