import { defineStore } from "pinia";
import { Question } from "@/types";
import { Visualisation } from "@/types/query";

interface MainState {
    version: string;
    refresh: number;
    visualisation: Visualisation;
    hasLoggedIn: boolean;
    menu: boolean;
    currentUserId: number | null;
    courseId: number;
    currentVersion: string;
    currentQuestion: Question | null;
}

export const useMainStore = defineStore("main", {
    state: () => {
        return {
            version: "",
            refresh: 0,
            visualisation: "graph",
            hasLoggedIn: false,
            menu: false,
            currentUserId: 19917,
            courseId: 1, // Autotheorie
            currentVersion: "2.0.0",
            currentQuestion: null,
        } as MainState;
    },
    getters: {
        versionStringToNumber(state) {
            if (state.currentVersion.includes("localhost")) {
                return 0;
            } else {
                const base = state.currentVersion.split("-")[0];
                const numbers = base.split(".");
                const major = numbers.length > 0 ? Number(numbers[0]) : 0;
                const minor = numbers.length > 1 ? Number(numbers[1]) : 0;
                const fix = numbers.length > 2 ? Number(numbers[2]) : 0;
                return major * 10000 + minor * 100 + fix;
            }
        },
    },
    actions: {},
});
