<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { AccessInfo } from "@/types";
import { useRoute, useRouter } from "vue-router";
import useFetch from "@/composables/useFetch";
import api from "@/api";
import LoginPanel from "./LoginPanel.vue";

interface AccessFetch {
    data: AccessInfo | null;
    request: () => void;
}
const route = useRoute();
const router = useRouter();

const fetch = ref<AccessFetch | null>(null);

const requiresAuthentication = computed(
    () => route.meta.requiresAuthentication
);

const accessData = computed(() => fetch.value?.data);

let authenticationRequested = false;
const requestAuthentication = () => {
    if (!authenticationRequested) {
        authenticationRequested = true;
        fetch.value = useFetch(api.getAccessInfo, true);
    }
};

const hasAccess = computed(() => {
    return !route.meta.requiresAuthentication || fetch.value?.isSuccessful;
});

const showLoader = computed(() => fetch.value?.showLoader);

const atSuccess = () => (fetch.value = useFetch(api.getAccessInfo, true));

watch(
    () => accessData.value,
    () => {
        if (accessData.value) {
            // store.access = accessData.value.access;
            // store.course = accessData.value.course;
            // store.user = accessData.value.user;
        }
    }
);

// if we change from a page without login to a login-required page
watch(
    () => requiresAuthentication.value,
    () => {
        if (requiresAuthentication.value && !accessData.value) {
            requestAuthentication();
        }
    }
);

onMounted(async () => {
    await router.isReady();
    if (route.meta.requiresAuthentication) {
        requestAuthentication();
    }
});
</script>

<template>
    <template v-if="showLoader">
        <etd-container align="center" justify="center" style="height: 100%">
            <etd-loading />
        </etd-container>
    </template>

    <template v-else>
        <template v-if="hasAccess">
            <slot />
        </template>

        <template v-else>
            <etd-popup>
                <template #title> Inloggen </template>

                <template #body>
                    <login-panel @success="atSuccess" :as-popup="true" />
                </template>
            </etd-popup>
        </template>
    </template>

    <div class="AccessLayer__info">
        {{ fetch }}
    </div>
</template>

<style lang="scss" scoped>
.AccessLayer {
    &__info {
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 100;
        background: yellow;
        padding: 20px;
    }
}
</style>
