import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import etdPlugin from "@innouveau/123-etd";
import i18n from "./i18n";
import { createPinia } from "pinia";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
    components,
    directives,
});

const app = createApp(App);

app.use(createPinia()).use(router).use(i18n).use(etdPlugin).mount("#app");
app.use(vuetify);

app.config.globalProperties.$pagewrap = 1200;
app.config.globalProperties.$stackedScreens = ["s"];
