import axios from "axios";
import { Api, Headers } from "@/types";

const api: Api = {};

axios.defaults.headers.common["content-type"] = "application/json";

const getHeaders = (withToken = false) => {
    const headers: Headers = {
        Accept: "application/json",
    };
    if (withToken) {
        headers.Authorization = "Bearer " + localStorage.token;
    }
    return headers;
};

api.logout = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "auth/logout";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                localStorage.token = "";
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getAccessInfo = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "access/details";
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getToken = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "auth/request-token";
        axios({
            method: "post",
            url,
            headers: getHeaders(false),
            data: {
                email,
                password,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.post = (route: string, data: unknown) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + route;
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data,
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.read = (route: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + route;

        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getUsers = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "users";
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getStatistics = (userId: number) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "statistics/" + userId;
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default api;
