import { RoutePath } from "@/types";
import { createRouter, createWebHistory } from "vue-router";
import { sectionRoutes } from "./sections";

const Main = () => import("@/components/main/index.vue");
const Info = () => import("@/components/pages/info/index.vue");

const routes: RoutePath[] = [
    {
        path: "/",
        name: "Main",
        component: Main,
        meta: {
            requiresAuthentication: true,
        },
    },
    {
        path: "/info",
        name: "Info",
        component: Info,
        meta: {
            requiresAuthentication: false,
        },
    },
    ...sectionRoutes,
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

export default router;
