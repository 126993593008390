<script lang="ts">
import { defineComponent, ref } from "vue";
import api from "@/api";
import { useRouter } from "vue-router";
import i18n from "@/i18n";
import { isBackendError } from "@/tools/handle-error";
import { ReturnedError } from "@/types";
import { FormConfig } from "@/types/etd";
import { useMainStore } from "@/stores/main";

interface AuthenticationResponse {
    access_token: string;
}

export default defineComponent({
    name: "LoginPanel",
    components: {},
    props: {
        asPopup: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const store = useMainStore();
        const router = useRouter();
        const { t } = i18n.global;

        const wrongCombinationEmailPassword = ref(false);

        const setToken = (token: string) => {
            localStorage.token = token;
        };

        const gotoForgotPassword = () => {
            router.push({ name: "ForgotPassword" });
        };

        const formValue = ref({
            email: "",
            password: "",
        });

        const formConfig = ref<FormConfig>({
            settings: {
                displayRequiredMark: false,
                displayPositiveFeedback: false,
                validateAt: "submit",
            },
            cards: [
                {
                    type: "transparent",
                    sections: [
                        {
                            type: "regular",
                            rows: [
                                {
                                    items: [
                                        {
                                            key: "email",
                                            label: "Email",
                                            placeholder: "email",
                                            required: true,
                                            type: "email",
                                            editable: true,
                                            validation: [
                                                {
                                                    hint: "Not allowed to be empty",
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    items: [
                                        {
                                            key: "password",
                                            label: "Password",
                                            required: true,
                                            type: "password",
                                            editable: true,
                                            validation: [
                                                {
                                                    hint: "Not allowed to be empty",
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        });

        const atSubmit = (payload: { email: string; password: string }) => {
            wrongCombinationEmailPassword.value = false;
            api.getToken(payload.email, payload.password)
                .then((response: AuthenticationResponse) => {
                    store.hasLoggedIn = true;
                    wrongCombinationEmailPassword.value = false;
                    setToken(response.access_token);
                    setTimeout(() => {
                        emit("success");
                    });
                })
                .catch((error: ReturnedError) => {
                    if (
                        isBackendError(error) &&
                        error.response.status === 400
                    ) {
                        wrongCombinationEmailPassword.value = true;
                        setToken("");
                        emit("fail");
                    } else {
                        // todo
                    }
                });
        };

        return {
            formValue,
            formConfig,
            wrongCombinationEmailPassword,
            gotoForgotPassword,
            atSubmit,
        };
    },
});
</script>

<template>
    <etd-container gap="xl">
        <etd-card v-if="!asPopup">
            <etd-form
                v-model:formValue="formValue"
                :form-config="formConfig"
                @submit="atSubmit"
            >
                <template #submit="{ atSubmit }">
                    <etd-button
                        id="login-submit"
                        theme="orange"
                        size="big"
                        @click="atSubmit"
                    >
                        {{ $t("login") }}
                    </etd-button>
                </template>
            </etd-form>

            <etd-form-error-message v-if="wrongCombinationEmailPassword">
                {{ $t("wrong_combination_email_password") }}
            </etd-form-error-message>
        </etd-card>

        <template v-else>
            <etd-form
                v-model:formValue="formValue"
                :form-config="formConfig"
                @submit="atSubmit"
            >
                <template #submit="{ atSubmit }">
                    <etd-button
                        id="login-submit"
                        theme="orange"
                        size="big"
                        @click="atSubmit"
                    >
                        Login
                    </etd-button>
                </template>
            </etd-form>

            <etd-form-error-message
                v-if="wrongCombinationEmailPassword"
                id="authentication-feedback"
            >
                {{ $t("wrong_combination_email_password") }}
            </etd-form-error-message>
        </template>
    </etd-container>
</template>

<style lang="scss" scoped></style>
