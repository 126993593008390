<script setup lang="ts">
import { provide, onMounted } from "vue";
import useMediaQuery from "@innouveau/123-etd/dist/composables/useMediaQuery.min.mjs";
import AccessLayer from "@/components/elements/access-layer/AccessLayer.vue";
import Sections from "@/components/elements/menu/Sections.vue";
import api from "@/api";
import { useRouter } from "vue-router";
import { useMainStore } from "@/stores/main";

const router = useRouter();
const { mq } = useMediaQuery();
const store = useMainStore();
provide("mq", mq);

const logout = async () => {
    await api.logout();
    await router.push({ name: "Main" });
    // reload the page to clear all state
    router.go(0);
};

const setVersion = async () => {
    const response = await fetch("/version.json");
    const json = await response.json();
    store.version = json.version;
};

onMounted(async () => {
    await setVersion();
});
</script>

<template>
    <access-layer>
        <etd-error-boundary>
            <template #content>
                <etd-page
                    enable-logo
                    main-full-width
                    main-full-height
                    hide-intro
                >
                    <template #nav-right>
                        <etd-icon-button
                            @click="logout"
                            icon-id="circle-user"
                            color="blue-5"
                        />
                    </template>

                    <template #main>
                        <etd-container direction="row" :n="0">
                            <sections />
                            <div class="main">
                                <router-view />
                            </div>
                        </etd-container>
                    </template>
                </etd-page>
            </template>
        </etd-error-boundary>
    </access-layer>
</template>

<style lang="scss">
@import "@innouveau/123-etd/dist/index.css";

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

.App {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.Sections {
    width: 300px;
    flex-shrink: 0;
}

.main {
    flex-grow: 1;
}
</style>
