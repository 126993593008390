import { createI18n } from "vue-i18n";
import nl from "./data/language/dictionaries/nl";

const i18n = createI18n({
    locale: "nl-NL",
    messages: {
        "nl-NL": nl,
    },
});

export default i18n;
